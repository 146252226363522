import { TinyEmitter } from 'tiny-emitter';
import { Component, inject, markRaw, onUnmounted, provide, reactive, readonly, ref } from 'vue';
import { useRouter } from 'vue-router';

interface Overlay {
  name: string;
  hash: string;
  status: string;
  component: Component;
  events: Record<string, (...args: unknown[]) => void>;
  props: Record<string, unknown>;
  isOpen: boolean;

  open(props?: Record<string, unknown>): void;
  close(): void;
}

class Outlet {
  active = ref<Overlay>();
  emitter = new TinyEmitter();

  constructor(public readonly name: string, public readonly parent?: Outlet) {}

  get hash(): string | undefined {
    return this.active.value?.hash ?? this.parent?.hash;
  }

  register(target: string, options: OverlayOptions): Overlay {
    if (this.name !== target) {
      if (!this.parent) {
        throw new Error(`Unknown overlay target "${target}"`);
      }

      return this.parent.register(target, options);
    }

    const overlay = reactive({
      name: options.name,
      hash: this.makeOverlayHash(options.name),
      status: 'closed',
      component: markRaw(options.component),
      events: markRaw(options.events ?? {}),
      props: markRaw({}),

      get isOpen() {
        return this.status === 'opened';
      },

      open: (props: Record<string, unknown> = {}) => {
        overlay.props = markRaw(props);
        overlay.status = 'opened';

        this.active.value = overlay;

        this.emitter.emit('open');
      },

      close: () => {
        if (overlay.status !== 'opened') {
          return;
        }

        this.active.value = undefined;
        overlay.status = 'closed';

        this.emitter.emit('close');
      },
    });

    return overlay;
  }

  on(event: 'close' | 'open', callback: () => void) {
    this.emitter.on(event, callback);
  }

  unregister() {
    this.emitter.off('open');
    this.emitter.off('close');

    this.active.value?.close();
    this.active.value = undefined;
  }

  private makeOverlayHash(name: string): string {
    if (this.parent?.hash) {
      return `${this.parent.hash}/${name}`;
    }

    return `#overlay=${name}`;
  }
}

const key = Symbol();

export function useOverlays(name: string) {
  const outlet = new Outlet(name, inject<Outlet | undefined>(key, undefined));

  provide(key, outlet);

  onUnmounted(() => {
    outlet.unregister();
  });

  const router = useRouter();

  outlet.on('open', async () => {
    await router.replace({
      ...router.currentRoute.value,
      hash: outlet.hash,
    });
  });

  outlet.on('close', async () => {
    await router.replace({
      ...router.currentRoute.value,
      hash: outlet.hash,
    });
  });
}

export function useOutlet() {
  const outlet = inject<Outlet>(key);

  if (!outlet) {
    throw new Error('Cannot be used outside of a "BaseOverlayProvider"');
  }

  return { outlet };
}

interface OverlayOptions {
  name: string;
  component: Component;
  events?: Record<string, (...args: unknown[]) => void>;
}

export function useOverlay(target: string, options: OverlayOptions) {
  const outlet = inject<Outlet>(key);

  if (!outlet) {
    throw new Error('Cannot be used outside of a "BaseOverlayProvider"');
  }

  const overlay = outlet.register(target, options);

  const router = useRouter();

  router.afterEach((to) => {
    if (!to.hash.startsWith(overlay.hash)) {
      overlay.close();
    }
  });

  return readonly(overlay);
}
