import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw, RouterView } from 'vue-router';

import OfferDeletedList from '@/components/offer/OfferDeletedList.vue';
import OfferFormView from '@/components/offer/OfferFormView.vue';
import OfferList from '@/components/offer/OfferList.vue';
import SearchFormView from '@/components/search/SearchFormView.vue';
import SearchOfferConsultedList from '@/components/search/SearchOfferConsultedList.vue';
import SearchOfferSavedList from '@/components/search/SearchOfferSavedList.vue';
import SearchOfferView from '@/components/search/SearchOfferView.vue';
import { acl, Permission } from '@/services/acl';
import config from '@/shared/config';
import translator from '@/shared/locale';
import { useAppStore } from '@/store/modules/app';
import Auth from '@/views/Auth.vue';
import DirectoryMyCustomerFile from '@/views/Directory/DirectoryMyCustomerFile.vue';
import DirectorySearch from '@/views/Directory/DirectorySearch.vue';
import FeatureNotAvailable from '@/views/FeatureNotAvailable.vue';
import NotFound from '@/views/NotFound.vue';
import Offer from '@/views/Offer.vue';
import Search from '@/views/Search.vue';
import Settings from '@/views/Settings.vue';
import FlowMapView from '@/views/Toolbox/FlowMapView.vue';
import RouteCalculatorView from '@/views/Toolbox/RouteCalculatorView.vue';
import WarehousesMapView from '@/views/Toolbox/WarehousesMapView.vue';
import VehicleForm from '@/views/Vehicle/VehicleForm.vue';
import VehicleList from '@/views/Vehicle/VehicleList.vue';
import VehicleSavedSearch from '@/views/Vehicle/VehicleSavedSearch.vue';
import VehicleSearch from '@/views/Vehicle/VehicleSearch.vue';

import availableRoutes from './routes';

const routes: RouteRecordRaw[] = [
  {
    path: '/auth',
    name: availableRoutes.auth,
    component: Auth,
    meta: {
      anonymous: true,
    },
    props: (route) => ({ redirect: route.query.redirect as string }),
  },

  {
    path: '/',
    name: availableRoutes.home,
    redirect: () => {
      if (useAppStore().isLoggedIn) {
        const defaultRoutes = [
          availableRoutes.search.list,
          availableRoutes.offer.list,
          availableRoutes.toolbox.directory,
          availableRoutes.directory.my,
          availableRoutes.vehicle.management.create,
          availableRoutes.vehicle.search.list,
        ];

        for (const routeName of defaultRoutes) {
          const location = router.resolve({ name: routeName });

          if (location.matched.some((record) => !acl.canAll(record.meta.permissions ?? []))) {
            continue;
          }

          return { name: routeName };
        }
      }

      return { name: availableRoutes.auth };
    },
  },

  {
    path: '/search',
    component: Search,
    meta: {
      permissions: [Permission.SearchManage],
      title: 'Recherche de Fret',
    },
    children: [
      {
        path: '',
        name: availableRoutes.search.list,
        component: SearchOfferView,
      },
      {
        path: 'create',
        name: availableRoutes.search.create,
        component: SearchFormView,
      },
      {
        path: ':searchId/edit',
        name: availableRoutes.search.edit,
        component: SearchFormView,
        props: true,
      },
      {
        path: 'offer/:offerId',
        name: availableRoutes.search.offerDisplay,
        component: SearchOfferView,
      },
      {
        path: 'history',
        name: availableRoutes.search.history.consulted,
        component: SearchOfferConsultedList,
      },
      {
        path: 'history/offer/:offerId',
        name: availableRoutes.search.history.consultedOfferDisplay,
        component: SearchOfferConsultedList,
      },
      {
        path: 'history/saved',
        name: availableRoutes.search.history.saved,
        component: SearchOfferSavedList,
      },
      {
        path: 'history/saved/offer/:offerId',
        name: availableRoutes.search.history.savedOfferDisplay,
        component: SearchOfferSavedList,
      },
    ],
  },

  {
    path: '/offer',
    component: Offer,
    meta: {
      permissions: [Permission.OfferManage],
      title: 'Dépose de Fret',
    },
    children: [
      {
        path: '',
        name: availableRoutes.offer.list,
        component: OfferList,
      },
      {
        path: ':offerId',
        name: availableRoutes.offer.display,
        component: OfferList,
      },
      {
        path: ':offerId/edit',
        name: availableRoutes.offer.edit,
        component: OfferFormView,
        props: true,
        meta: {
          permissions: [Permission.OfferUpdate],
        },
      },
      {
        path: ':offerId/duplicate',
        name: availableRoutes.offer.duplicate,
        component: OfferFormView,
        props: true,
        meta: {
          permissions: [Permission.OfferDuplicate],
        },
      },
      {
        path: 'create',
        name: availableRoutes.offer.create,
        component: OfferFormView,
        meta: {
          permissions: [Permission.OfferCreate],
        },
      },
      {
        path: 'supplement',
        name: availableRoutes.offer.supplement,
        component: OfferList,
        meta: {
          permissions: [Permission.OfferSearchSupplement],
        },
      },
      {
        path: 'deleted',
        name: availableRoutes.offer.deleted.list,
        component: OfferDeletedList,
      },
      {
        path: 'deleted/:offerId',
        name: availableRoutes.offer.deleted.display,
        component: OfferDeletedList,
      },
      {
        path: 'deleted/:offerId/duplicate',
        name: availableRoutes.offer.deleted.duplicate,
        component: OfferFormView,
        props: true,
        meta: {
          permissions: [Permission.OfferDuplicate],
        },
      },
    ],
  },

  {
    path: '/vehicle',
    component: RouterView,
    meta: {
      permissions: [Permission.VehicleManage],
    },
    children: [
      {
        path: '',
        name: availableRoutes.vehicle.management.list,
        component: VehicleList,
      },
      {
        path: ':id',
        name: availableRoutes.vehicle.management.display,
        component: VehicleList,
      },
      {
        path: 'create',
        name: availableRoutes.vehicle.management.create,
        component: VehicleForm,
      },
      {
        path: ':id/edit',
        name: availableRoutes.vehicle.management.edit,
        component: VehicleForm,
        props: true,
      },
      {
        path: ':id/duplicate',
        name: availableRoutes.vehicle.management.duplicate,
        component: VehicleForm,
        props: true,
      },
    ],
  },
  {
    path: '/vehicle/search',
    component: RouterView,
    meta: {
      permissions: [Permission.VehicleSearch],
    },
    children: [
      {
        path: '',
        name: availableRoutes.vehicle.search.list,
        component: VehicleSearch,
      },
      {
        path: ':id',
        name: availableRoutes.vehicle.search.display,
        component: VehicleSearch,
      },
      {
        path: 'saved',
        name: availableRoutes.vehicle.search.saved,
        component: VehicleSavedSearch,
      },
      {
        path: 'saved/:id',
        name: availableRoutes.vehicle.search.savedDisplay,
        component: VehicleSavedSearch,
      },
    ],
  },

  {
    path: '/directory',
    component: RouterView,
    meta: {
      title: 'Annuaire',
    },
    children: [
      {
        path: '',
        name: availableRoutes.toolbox.directory,
        component: DirectorySearch,
        meta: {
          permissions: [Permission.DirectorySearch],
        },
      },
      {
        path: ':id',
        name: availableRoutes.directory.listDisplay,
        component: DirectorySearch,
        meta: {
          permissions: [Permission.DirectorySearch],
        },
      },
      {
        path: 'my',
        name: availableRoutes.directory.my,
        component: DirectoryMyCustomerFile,
        meta: {
          permissions: [Permission.DirectoryManageMyCustomerFile],
        },
      },
    ],
  },

  {
    path: '/settings',
    name: availableRoutes.settings,
    component: Settings,
  },

  {
    path: '/toolbox/flow-map',
    name: availableRoutes.toolbox.flowMap,
    component: FlowMapView,
  },

  {
    path: '/toolbox/route-calculator',
    name: availableRoutes.toolbox.routeCalculator,
    component: RouteCalculatorView,
  },

  {
    path: '/toolbox/warehouses-map',
    name: availableRoutes.toolbox.warehousesMap,
    component: WarehousesMapView,
  },

  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(config.urls.appBaseUrl),
  routes,
});

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next) => {
  if (!useAppStore().isLoggedIn && !to.matched.some((record) => record.meta.anonymous)) {
    next({ name: availableRoutes.auth, query: { redirect: to.fullPath } });
    return;
  }

  if (to.matched.some((record) => !acl.canAll(record.meta.permissions ?? []))) {
    next({ name: availableRoutes.home });
    return;
  }

  next();
});

if (config.env === 'beta') {
  const featureNotAvailableRouteName = 'feature-not-available';

  const hasBetaAccess = (route: RouteLocationNormalized) => {
    const enabledFeaturePermissions = config.enabledBetaFeatures
      ? config.enabledBetaFeatures.split(',')
      : [Permission.SearchManage];

    return route.matched.every(
      (record) =>
        !record.meta.permissions ||
        record.meta.permissions.some((permission) => enabledFeaturePermissions.includes(permission))
    );
  };

  router.addRoute({
    path: '/feature-not-available',
    name: featureNotAvailableRouteName,
    component: FeatureNotAvailable,
  });

  router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next) => {
    if (to.name !== featureNotAvailableRouteName && !hasBetaAccess(to)) {
      next({ name: featureNotAvailableRouteName });
      return;
    }

    next();
  });
}

router.afterEach((to) => {
  let title = `B2PWeb - ${translator().t('Bourse Premium Professionnel')}`;

  if (to.meta && to.meta.title) {
    title = `${translator().t(to.meta.title)} - ${title}`;
  }

  document.title = title;
});

acl.afterUpdate(async () => {
  if (router.currentRoute.value.matched.every((record) => acl.canAll(record.meta.permissions ?? []))) {
    return;
  }

  await router.push({ name: availableRoutes.home });
});

export default router;
