import { DateTime } from 'luxon';

import * as readApi from '@/shared/api/offer/read';
import {
  Consultation as ApiConsultation,
  DeletedOffer as ApiDeletedOffer,
  Offer as ApiOffer,
  OfferAction as ApiOfferAction,
  OfferActionExchange as ApiOfferActionExchange,
  OfferCore as ApiOfferCore,
  SketchyOffer as ApiSketchyOffer,
  Template as ApiTemplate,
} from '@/shared/api/offer/types';
import * as writeApi from '@/shared/api/offer/write';
import { GoodType } from '@/shared/enums/goodType';
import { isTruckType } from '@/shared/enums/truckType';
import {
  Consultation,
  DeletedOffer,
  Offer,
  OfferAction,
  OfferActionExchange,
  OfferActionExchangeStatus,
  OfferActionType,
  OfferCore,
  OfferDeliveryInput,
  OfferInput,
  OfferLadingInput,
  OfferListFilters,
  OfferTemplate,
  SketchyOffer,
  StatisticInput,
} from '@/shared/types';

export * from './offer/providers';
export * from './offer/types';

function composeOfferCore(apiOffer: ApiOfferCore): OfferCore {
  return {
    id: apiOffer.id,
    userId: apiOffer.userId,
    price: apiOffer.price,
    contact: apiOffer.contact,
    customerContact: apiOffer.customerContact,
    goodType: apiOffer.goodType as GoodType,
    truckTypes: apiOffer.truckTypes.filter(isTruckType),
    lading: apiOffer.lading,
    delivery: apiOffer.delivery,
    hazardousMaterials: apiOffer.hazardousMaterials,
    weight: apiOffer.weight || 0,
    length: apiOffer.length || 0,
    volume: apiOffer.volume,
    distance: apiOffer.distance,
    notes: apiOffer.notes,
    orderRef: apiOffer.orderRef,
    profileId: apiOffer.profileId,
    lastActionDate: apiOffer.lastActionDate,
  };
}

function composeDeletedOffer(apiOffer: ApiDeletedOffer): DeletedOffer {
  return {
    ...composeOfferCore(apiOffer),

    deleteCause: apiOffer.deleteCause,
  };
}

function composeSketchyOffer(apiOffer: ApiSketchyOffer): SketchyOffer {
  return {
    ...composeOfferCore(apiOffer),

    hasError: apiOffer.hasError,
  };
}

function composeOffer(apiOfferDetail: ApiOffer): Offer {
  return {
    ...composeSketchyOffer(apiOfferDetail),

    states: apiOfferDetail.states,
  };
}

function composeTemplate(apiOfferTemplate: ApiTemplate): OfferTemplate {
  return {
    id: apiOfferTemplate.id,
    userId: apiOfferTemplate.userId,
    price: apiOfferTemplate.price,
    contact: apiOfferTemplate.contact,
    customerContact: apiOfferTemplate.customerContact,
    goodType: apiOfferTemplate.goodType as GoodType,
    truckTypes: apiOfferTemplate.truckTypes.filter(isTruckType),
    lading: apiOfferTemplate.lading,
    delivery: apiOfferTemplate.delivery,
    hazardousMaterials: apiOfferTemplate.hazardousMaterials,
    weight: apiOfferTemplate.weight,
    length: apiOfferTemplate.length,
    volume: apiOfferTemplate.volume,
    distance: apiOfferTemplate.distance,
    notes: apiOfferTemplate.notes,
    orderRef: apiOfferTemplate.orderRef,
    profileId: apiOfferTemplate.profileId,
  };
}

function composeOfferAction(source: ApiOfferAction): OfferAction {
  return {
    type: composeOfferActionType(source.type),
    exchanges: source.actionExchanges.map((source) => composeOfferActionExchange(source)),
    creationDate: source.createdAt,
  };
}

function composeOfferActionType(source: number): OfferActionType {
  switch (source) {
    case 1:
      return OfferActionType.Post;
    case 2:
    case 9:
      return OfferActionType.Delete;
    case 3:
    case 10:
      return OfferActionType.Update;
    case 4:
    case 7:
    case 11:
    case 14:
      return OfferActionType.Refresh;
    case 16:
      return OfferActionType.AutoDelete;
    default:
      return OfferActionType.Unknown;
  }
}

function composeOfferActionExchange(source: ApiOfferActionExchange): OfferActionExchange {
  return {
    name: source.label,
    shortname: source.abbreviation,
    status: composeOfferActionExchangeStatus(source.code),
  };
}

function composeOfferActionExchangeStatus(source: string): OfferActionExchangeStatus {
  switch (source.substring(0, 1)) {
    case 'K':
      return OfferActionExchangeStatus.Success;
    case 'N':
      return OfferActionExchangeStatus.Failure;
    default:
      return OfferActionExchangeStatus.Pending;
  }
}

function composeConsultation(apiConsultation: ApiConsultation): Consultation {
  return {
    customer: {
      id: apiConsultation.customer.id,
      siret: apiConsultation.customer.siret,
      name: apiConsultation.customer.name,
      address1: apiConsultation.customer.address1,
      zipCode: apiConsultation.customer.zipCode,
      city: apiConsultation.customer.city,
      country: apiConsultation.customer.country,
      dateInsert: apiConsultation.customer.dateInsert,
    },
    user: {
      id: apiConsultation.user.id,
      name: apiConsultation.user.name || null,
      phone: apiConsultation.user.phone || null,
      fax: apiConsultation.user.fax || null,
      email: apiConsultation.user.email || null,
    },
    createdAt: apiConsultation.createdAt,
  };
}

function composeApiCreateOfferInput(input: OfferInput): writeApi.CreateOfferInput {
  return {
    ...composeApiOfferInput(input),
    contact: input.contact,
    differedAt: input.differedAt?.set({ millisecond: 0 }).toISO({ suppressMilliseconds: true }),
    profileId: input.profileId,
    saveAsTemplate: input.saveAsTemplate,
  };
}

function composeApiOfferInput(input: OfferInput): writeApi.OfferInput {
  return {
    lading: composeApiLadingInput(input.lading),
    delivery: composeApiDeliveryInput(input.delivery),
    goodType: input.goodType,
    truckTypes: input.truckTypes,
    hazardousMaterials: input.hazardousMaterials,
    weight: input.weight,
    length: input.length,
    volume: input.volume,
    notes: input.notes,
    orderRef: input.orderRef,
    price: input.price,
  };
}

function composeApiLadingInput(input: OfferLadingInput): writeApi.LadingInput {
  return {
    city: input.city,
    zipCode: input.zipCode,
    zone: input.zone,
    country: input.country,
    latitude: input.latitude,
    longitude: input.longitude,
    dateStart: input.dateStart.set({ millisecond: 0 }).toISO({ suppressMilliseconds: true }),
    dateEnd: input.dateEnd?.set({ millisecond: 0 }).toISO({ suppressMilliseconds: true }),
  };
}

function composeApiDeliveryInput(input: OfferDeliveryInput): writeApi.DeliveryInput {
  return {
    city: input.city,
    zipCode: input.zipCode,
    zone: input.zone,
    country: input.country,
    latitude: input.latitude,
    longitude: input.longitude,
    dateStart: input.dateStart?.set({ millisecond: 0 }).toISO({ suppressMilliseconds: true }),
    dateEnd: input.dateEnd?.set({ millisecond: 0 }).toISO({ suppressMilliseconds: true }),
  };
}

function composeApiStatisticInput(input: StatisticInput): writeApi.StatisticInput {
  return { ...input };
}

export function createOfferInputFrom(offer: OfferCore | OfferTemplate, options?: { sanitize: boolean }): OfferInput {
  const input: OfferInput = {
    lading: {
      country: offer.lading.country,
      city: offer.lading.city,
      zipCode: offer.lading.zipCode,
      zone: offer.lading.zone,
      latitude: offer.lading.latitude,
      longitude: offer.lading.longitude,
      dateStart: offer.lading.startDate,
      dateEnd: offer.lading.endDate ?? undefined,
    },
    delivery: {
      country: offer.delivery.country,
      city: offer.delivery.city,
      zipCode: offer.delivery.zipCode,
      zone: offer.delivery.zone,
      latitude: offer.delivery.latitude,
      longitude: offer.delivery.longitude,
      dateStart: offer.delivery.startDate ?? undefined,
      dateEnd: offer.delivery.endDate ?? undefined,
    },
    hazardousMaterials: offer.hazardousMaterials,
    goodType: offer.goodType,
    truckTypes: offer.truckTypes,
    weight: offer.weight,
    length: offer.length,
    volume: offer.volume ?? undefined,
    notes: offer.notes,
    orderRef: offer.orderRef,
    price: offer.price?.value,
    contact: {
      id: offer.contact.id,
      civility: offer.contact.civility ?? undefined,
      name: offer.contact.name ?? undefined,
      email: offer.contact.email ?? undefined,
      fax: offer.contact.fax ?? undefined,
      phone: offer.contact.phone ?? undefined,
      mobile: offer.contact.mobile ?? undefined,
      languages: offer.contact.languages ?? undefined,
    },
    profileId: offer.profileId,
  };

  if (options?.sanitize) {
    const now = DateTime.local().startOf('day');

    if (input.lading.dateStart < now) {
      input.lading.dateStart = now;
    }

    if (input.lading.dateEnd && input.lading.dateEnd <= input.lading.dateStart) {
      input.lading.dateEnd = undefined;
    }

    if (!input.delivery.dateStart || input.delivery.dateStart < input.lading.dateStart) {
      input.delivery.dateStart = undefined;
      input.delivery.dateEnd = undefined;
    } else if (input.delivery.dateEnd && input.delivery.dateEnd <= input.delivery.dateStart) {
      input.delivery.dateEnd = undefined;
    }
  }

  return input;
}

export async function fetchOffer(id: string): Promise<Offer | undefined> {
  const apiOffer = await readApi.fetchOffer(id);

  if (!apiOffer) {
    return undefined;
  }

  return composeOffer(apiOffer);
}

export async function fetchSketchyOffers(
  options: OfferListFilters & readApi.RequestPagination & readApi.RequestSorting = {},
  signal?: AbortSignal
): Promise<readApi.Collection<SketchyOffer>> {
  const collection = await readApi.fetchSketchyOffers(options, signal);

  return {
    ...collection,
    items: collection.items.map(composeSketchyOffer),
  };
}

export async function fetchDeletedOffers(
  options: OfferListFilters & readApi.RequestPagination & readApi.RequestSorting = {},
  signal?: AbortSignal
): Promise<readApi.Collection<DeletedOffer>> {
  const collection = await readApi.fetchDeletedOffers(options, signal);

  return {
    ...collection,
    items: collection.items.map(composeDeletedOffer),
  };
}

export async function fetchDeletedOffer(id: string): Promise<DeletedOffer | undefined> {
  const apiOffer = await readApi.fetchDeletedOffer(id);

  if (!apiOffer) {
    return undefined;
  }

  return composeDeletedOffer(apiOffer);
}

export async function createOffer(input: OfferInput): Promise<Offer> {
  return composeOffer(await writeApi.createOffer(composeApiCreateOfferInput(input)));
}

export async function updateOffer(id: string, input: OfferInput): Promise<Offer> {
  return composeOffer(await writeApi.updateOffer(id, composeApiOfferInput(input)));
}

export async function cloneOffer(offer: OfferTemplate): Promise<Offer> {
  return composeOffer(
    await writeApi.createOffer(composeApiCreateOfferInput(createOfferInputFrom(offer, { sanitize: true })))
  );
}

export async function refreshOffers(
  filters: OfferListFilters
): Promise<{ done: number; errors: { id: string; message: string; code: number }[] }> {
  return await writeApi.refreshOffers(filters);
}

export async function refreshOffer(id: string): Promise<void> {
  await writeApi.refreshOffer(id);
}

export async function removeOffers(filters: OfferListFilters): Promise<number> {
  return await writeApi.removeOffers(filters);
}

export async function removeOffer(id: string, reason?: string): Promise<void> {
  await writeApi.removeOffer(id, reason);
}

export async function fetchOfferLastAction(id: string, signal?: AbortSignal): Promise<OfferAction | undefined> {
  const actions = await readApi.fetchOfferActions(id, {
    perPage: 1,
    signal,
  });

  if (!actions.items.length) {
    return undefined;
  }

  return composeOfferAction(actions.items[0]);
}

export async function fetchOfferActions(
  id: string,
  options?: readApi.RequestPagination & readApi.RequestCancellationOptions
): Promise<readApi.Collection<OfferAction>> {
  const collection = await readApi.fetchOfferActions(id, options);

  return {
    ...collection,
    items: collection.items.map((action) => composeOfferAction(action)),
  };
}

export async function fetchDeletedOfferLastAction(id: string, signal?: AbortSignal): Promise<OfferAction | undefined> {
  const actions = await readApi.fetchDeletedOfferActions(id, {
    perPage: 1,
    signal,
  });

  if (!actions.items.length) {
    return undefined;
  }

  return composeOfferAction(actions.items[0]);
}

export async function fetchDeletedOfferActions(
  id: string,
  options?: readApi.RequestPagination & readApi.RequestCancellationOptions
): Promise<readApi.Collection<OfferAction>> {
  const collection = await readApi.fetchDeletedOfferActions(id, options);

  return {
    ...collection,
    items: collection.items.map((action) => composeOfferAction(action)),
  };
}

export async function fetchOfferTemplates(
  options: readApi.RequestPagination = {}
): Promise<readApi.Collection<OfferTemplate>> {
  const collection = await readApi.fetchTemplates(options);

  return {
    ...collection,

    items: collection.items.map(composeTemplate),
  };
}

export async function fetchConsultations(id: string, typeHint: readApi.OfferType): Promise<Consultation[]> {
  return (await readApi.fetchConsultations(id, typeHint)).map(composeConsultation);
}

export function updateStatistic(offerId: string, input: StatisticInput): Promise<void> {
  return writeApi.updateStatistic(offerId, composeApiStatisticInput(input));
}
