import { DateTime } from 'luxon';

import axios, { isAxiosError } from '../axios';
import { ApiContact, Contact, fromApi as fromContactApi } from './contact';
import { ApiCustomerContact, fromApi as fromCustomerContactApi } from './contact-customer';
import { ForbiddingLevel, SearchOffer } from './list';
import { ApiLocation, fromApi as fromLocationApi } from './location';
import { fromApi as fromPriceApi, Price } from './price';

const CONSULTED_STATE = 6;

export interface ApiSearchOffer {
  id: string;
  customerContact: ApiCustomerContact;
  forbiddingLevel: ForbiddingLevel;
  goodType: string;
  truckTypes: string[];
  lading: ApiLocation;
  delivery: ApiLocation;
  hazardousMaterials: boolean;
  weight: number | null;
  length: number | null;
  volume: number | null;
  distance: number | null;
  exchangeType: number;
  state: number;
  createdAt: string;
  updatedAt: string;
  contact: ApiContact | null;
  notes: string | null;
  orderRef: string | null;
  price: Price | null;
}

export interface SearchOfferDetail extends SearchOffer {
  contact: Contact | null;
  notes: string | null;
  price: Price | null;
  orderRef: string | null;
}

export function fromApi(data: ApiSearchOffer): SearchOfferDetail {
  return {
    id: data.id,
    customer: fromCustomerContactApi(data.customerContact),
    forbiddingLevel: data.forbiddingLevel,
    goodType: String(data.goodType),
    truckTypes: data.truckTypes,
    lading: fromLocationApi(data.lading),
    delivery: fromLocationApi(data.delivery),
    hazardousMaterials: data.hazardousMaterials,
    weight: data.weight,
    length: data.length,
    volume: data.volume,
    distance: data.distance,
    exchangeType: data.exchangeType,
    isConsulted: data.state === CONSULTED_STATE,
    createdAt: DateTime.fromISO(data.createdAt),
    updatedAt: DateTime.fromISO(data.updatedAt),
    contact: fromContactApi(data.contact || {}),
    notes: data.notes,
    orderRef: data.orderRef,
    price: data.price ? fromPriceApi(data.price) : null,
  };
}

export async function get(searchId: string, offerId: string): Promise<SearchOfferDetail | undefined> {
  try {
    // @note ${searchId || 'null'} while api require searchId in path
    const { data } = await axios.get<ApiSearchOffer>(`searches/${searchId || 'null'}/offers/${offerId}`);
    return fromApi(data);
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 404) {
      return undefined;
    }

    throw e;
  }
}
