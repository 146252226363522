import { DateTime } from 'luxon';

import axios, { isAxiosError } from '../axios';
import { ApiCollectionResponse, Collection, FormValidationError, PaginationOptions, ValidationError } from '../utils';
import { ApiContact, Contact, fromApi as fromContactApi } from './contact';
import { ApiCustomerContact, CustomerContact, fromApi as fromCustomerContactApi } from './contact-customer';
import { ApiLocation, fromApi as fromLocationApi, Location } from './location';
import { fromApi as fromPriceApi, Price } from './price';

const apiMapping = {
  user_notes: 'userNotes',
  user_price: 'userPrice',
};

function isKeyOfApiMapping(key: string): key is keyof typeof apiMapping {
  return key in apiMapping;
}

export interface ApiConsultedOffer {
  id: string;
  customerContact: ApiCustomerContact;
  goodType: string;
  truckTypes: string[];
  lading: ApiLocation;
  delivery: ApiLocation;
  hazardousMaterials: boolean;
  weight: number | null;
  length: number | null;
  volume: number | null;
  distance: number | null;
  type: number;
  consultedAt: string;
  contact: ApiContact | null;
  notes: string | null;
  orderRef: string | null;
  price: Price | null;
  saved: boolean;
  userNotes: string | null;
  userPrice: Price | null;
}

export interface ConsultedSearchOffer {
  id: string;
  customer: CustomerContact;
  goodType: string;
  truckTypes: string[];
  lading: Location;
  delivery: Location;
  hazardousMaterials: boolean;
  weight: number | null;
  length: number | null;
  volume: number | null;
  distance: number | null;
  consultedAt: DateTime;
  contact: Contact | null;
  notes: string | null;
  price: Price | null;
  orderRef: string | null;
  isBookmarked: boolean;
  userNotes: string | null;
  userPrice: Price | null;
}

export function fromApi(data: ApiConsultedOffer): ConsultedSearchOffer {
  return {
    id: data.id || '',
    customer: fromCustomerContactApi(data.customerContact),
    goodType: String(data.goodType),
    truckTypes: data.truckTypes,
    lading: fromLocationApi(data.lading),
    delivery: fromLocationApi(data.delivery),
    hazardousMaterials: data.hazardousMaterials,
    weight: data.weight,
    length: data.length,
    volume: data.volume,
    distance: data.distance,
    consultedAt: DateTime.fromISO(data.consultedAt),
    contact: fromContactApi(data.contact || {}),
    notes: data.notes,
    orderRef: data.orderRef,
    price: data.price ? fromPriceApi(data.price) : null,
    isBookmarked: data.type === 2,
    userNotes: data.userNotes,
    userPrice: data.userPrice ? fromPriceApi(data.userPrice) : null,
  };
}

export interface ConsultedSearchOfferListFilters {
  since?: DateTime;
  zones?: string[];
  country?: string;
  saved?: boolean;
  minWeight?: number;
  maxWeight?: number;
}

export async function list(
  options: ConsultedSearchOfferListFilters & PaginationOptions = {}
): Promise<Collection<ConsultedSearchOffer>> {
  const { since, country, zones, saved, minWeight, maxWeight, page = 1, perPage = 40 } = options;
  const { data } = await axios.get<ApiCollectionResponse<ApiConsultedOffer>>(`searches/offers/consulted`, {
    params: {
      since: since?.set({ millisecond: 0 }).toISO({ suppressMilliseconds: true }),
      country,
      zones,
      saved: saved,
      weight_min: minWeight,
      weight_max: maxWeight,
      page,
      per_page: perPage,
    },
  });

  return {
    items: data.items.map(fromApi),
    page: data.pagination.currentPage,
    pageCount: data.pagination.pageCount,
    limit: data.pagination.limit,
    totalCount: data.pagination.totalCount,
  };
}

export async function get(id: string): Promise<ConsultedSearchOffer | undefined> {
  try {
    const { data } = await axios.get<ApiConsultedOffer>(`searches/offers/consulted/${id}`);
    return fromApi(data);
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 404) {
      return undefined;
    }

    throw e;
  }
}

export async function bookmark(id: string, { price, notes }: { price?: number; notes?: string }): Promise<void> {
  await axios.patch(`searches/offers/consulted/${id}/bookmark`, {
    userPrice: price,
    userNotes: notes,
  });
}

export async function unbookmark(ids: string | string[]): Promise<void> {
  if (!Array.isArray(ids)) {
    ids = [ids];
  }

  await axios.delete(`searches/offers/consulted`, {
    params: {
      offerIds: ids,
    },
  });
}

export async function updateBookmark(
  id: string,
  { price, notes }: { price?: number; notes?: string }
): Promise<ConsultedSearchOffer> {
  try {
    const { data } = await axios.put<ApiConsultedOffer>(`searches/offers/consulted/${id}`, {
      userPrice: price,
      userNotes: notes,
    });

    return fromApi(data);
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 400) {
      throw new FormValidationError(
        'Error occurred while updating bookmark',
        e.response.data.errors?.map((error: ValidationError) => {
          if (isKeyOfApiMapping(error.field)) {
            error.field = apiMapping[error.field];
          }
          return error;
        }) ?? []
      );
    }

    throw e;
  }
}

export async function exportSearchOffers(ids: string[]): Promise<Blob> {
  const { data } = await axios.get('searches/offers/consulted/export', {
    responseType: 'blob',
    params: { offerIds: ids },
  });
  return data;
}

export async function sendCustomerInfos(id: string, comments?: string): Promise<void> {
  await axios.post(`searches/offers/consulted/${id}/sendCustomerInfos`, {
    comments,
  });
}
