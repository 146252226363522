import 'hammerjs';

import EventHandler from '@/shared/components/state-selector/event/handlers/EventHandler';

export default class EventManager {
  private readonly hammer: HammerManager;

  constructor(public readonly root: SVGElement, private handlers: EventHandler[]) {
    this.hammer = new Hammer.Manager(root, {
      recognizers: [
        [Hammer.Pan],
        [Hammer.Pinch, { threshold: 0.1 }],
        [Hammer.Press],
        [Hammer.Tap, { event: 'double-tap', taps: 2 }],
        [Hammer.Tap, { event: 'single-tap', taps: 1 }],
      ],
    });
  }

  registerHooks(): void {
    this.handlers.forEach((handler) => {
      handler.registerHook(this.hammer, this.root);
    });
  }

  unregisterHooks(): void {
    this.handlers.forEach((handler) => {
      handler.unregisterHook(this.hammer, this.root);
    });
  }
}
