import { DateTime } from 'luxon';

import { Collection } from '@/shared/api/offer/read';
import {
  Consultation,
  Contact,
  CustomerContact,
  DeletedOffer,
  DeletedSketchyOffer,
  Delivery,
  Lading,
  Location,
  Offer,
  OfferAction,
  OfferActionExchange,
  OfferCore,
  SketchyOffer,
  Template,
} from '@/shared/api/offer/types';
import {
  ApiConsultation,
  ApiContact,
  ApiCustomerContact,
  ApiDeletedOffer,
  ApiDeletedSketchyOffer,
  ApiDelivery,
  ApiLading,
  ApiLocation,
  ApiOffer,
  ApiOfferAction,
  ApiOfferActionExchange,
  ApiOfferCore,
  ApiPaginatedCollectionResponse,
  ApiSketchyOffer,
  ApiTemplate,
} from '@/shared/api/offer/types-api';

export function composeContact(data?: ApiContact): Contact {
  return {
    id: data?.id || '',
    civility: data?.civility || null,
    name: data?.name || '',
    phone: data?.phone || null,
    fax: data?.fax || null,
    mobile: data?.mobile || null,
    email: data?.email || null,
    languages: data?.languages || [],
  };
}

export function composeCustomerContact(data: ApiCustomerContact): CustomerContact {
  return {
    id: data.id,
    name: data.name,
    country: data.country,
    zone: data.zone,
    logo: data.logo,
  };
}

function composeLocation(data: ApiLocation): Location {
  return {
    city: data.city,
    zipCode: data.zipCode,
    zone: data.zone,
    country: data.country,
    latitude: data.latitude,
    longitude: data.longitude,
  };
}

export function composeLading(data: ApiLading): Lading {
  return {
    ...composeLocation(data),

    startDate: DateTime.fromISO(data.dateStart, { setZone: true }),
    endDate: data.dateEnd ? DateTime.fromISO(data.dateEnd, { setZone: true }) : null,
  };
}

export function composeDelivery(data: ApiDelivery): Delivery {
  return {
    ...composeLocation(data),

    startDate: data.dateStart ? DateTime.fromISO(data.dateStart, { setZone: true }) : null,
    endDate: data.dateEnd ? DateTime.fromISO(data.dateEnd, { setZone: true }) : null,
  };
}

export function composeOfferCore(data: ApiOfferCore): OfferCore {
  return {
    id: data.id,
    userId: data.userId,
    price: data.price,
    contact: composeContact(data.contact),
    customerContact: composeCustomerContact(data.customerContact),
    goodType: String(data.goodType),
    truckTypes: data.truckTypes.map((value) => String(value)),
    lading: composeLading(data.lading),
    delivery: composeDelivery(data.delivery),
    hazardousMaterials: data.hazardousMaterials,
    weight: data.weight,
    length: data.length,
    volume: data.volume,
    distance: data.distance,
    notes: data.notes || '',
    orderRef: data.orderRef || '',
    profileId: data.profileId,
    lastActionDate: DateTime.fromISO(data.lastActionDate),
  };
}

export function composeDeletedSketchyOffer(data: ApiDeletedSketchyOffer): DeletedSketchyOffer {
  return {
    ...composeOfferCore(data),

    deleteCause: data.deleteCause,
  };
}

export function composeDeletedOffer(data: ApiDeletedOffer): DeletedOffer {
  return {
    ...composeDeletedSketchyOffer(data),

    states: data.states,
  };
}

export function composeSketchyOffer(data: ApiSketchyOffer): SketchyOffer {
  return {
    ...composeOfferCore(data),

    hasError: data.hasError,
  };
}

export function composeOffer(data: ApiOffer): Offer {
  return {
    ...composeSketchyOffer(data),

    states: data.states,
  };
}

export function composeTemplate(data: ApiTemplate): Template {
  return {
    id: data.id,
    userId: data.userId,
    price: data.price,
    contact: composeContact(data.contact),
    customerContact: composeCustomerContact(data.customerContact),
    goodType: String(data.goodType),
    truckTypes: data.truckTypes.map((value) => String(value)),
    lading: composeLading(data.lading),
    delivery: composeDelivery(data.delivery),
    hazardousMaterials: data.hazardousMaterials,
    weight: data.weight,
    length: data.length,
    volume: data.volume,
    distance: data.distance,
    notes: data.notes || '',
    orderRef: data.orderRef || '',
    profileId: data.profileId,
  };
}

export function composeConsultation(data: ApiConsultation): Consultation {
  return {
    customer: {
      id: data.customerId,
      siret: data.customerSiret,
      name: data.customerName,
      address1: data.customerAddress1,
      zipCode: data.customerZipCode,
      city: data.customerCity,
      country: data.customerCountry,
      dateInsert: data.customerDateInsert ? DateTime.fromISO(data.customerDateInsert) : null,
    },
    user: {
      id: data.userId,
      name: data.userName,
      phone: data.userPhone,
      fax: data.userFax,
      email: data.userEmail,
    },
    createdAt: DateTime.fromISO(data.createdAt),
  };
}

export function composeOfferAction(data: ApiOfferAction): OfferAction {
  return {
    type: data.type,
    actionExchanges: data.actionExchanges.map((actionExchange) => composeOfferActionExchange(actionExchange)),
    createdAt: DateTime.fromISO(data.createdAt),
    tracedAt: DateTime.fromISO(data.tracedAt),
  };
}

export function composeOfferActionExchange(data: ApiOfferActionExchange): OfferActionExchange {
  return {
    label: data.label,
    abbreviation: data.abbreviation,
    code: data.code,
    type: data.type,
    createdAt: DateTime.fromISO(data.createdAt),
  };
}

export function composeCollection<Source, Target>(
  data: ApiPaginatedCollectionResponse<Source>,
  composeItem: (item: Source) => Target
): Collection<Target> {
  return {
    items: data.items.map((item) => composeItem(item)),
    page: data.pagination.currentPage,
    pageCount: data.pagination.pageCount,
    limit: data.pagination.limit,
    totalCount: data.pagination.totalCount,
  };
}
